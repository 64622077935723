import React, {useState, useEffect} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import dateFormat from 'dateformat';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import SEO from "../../components/seo"
import {config} from '../../components/Common/constant';

import ProfileImg1 from "../../assests/images/profile1.png";

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import ThnakYou from  "../../components/modules/thank_you"; 

import {isLoggedIn, isBrowser, logout, getUser } from "../../services/auth"

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {

    const context = useContainerContext();
    const { property_data, seller_data } = context.state;
    let logged_user = getUser();

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('preval-presentation-confirmation');

    useEffect(()=>{
      setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    var property_id = property_data && property_data.id;

    //console.log("split_preview_url", split_preview_url);

    var valuation_date = seller_data && seller_data.valuation_date;

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

        <div className="pre-wrapper less_pad">

        <Header />

        <ThnakYou logged_user={logged_user.logged_user_data}/>

        {/* contact-wrapper */}

        <NavLink tab_name="" prevLink="../valuation-day" nextLink="" />

        </div>
    </>
}

export default PresentationPage