import React, {useEffect, useState}  from "react"
import dateFormat from 'dateformat';
import moment from 'moment';
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"

import {config} from '../../components/Common/constant';

import VideoModule from "./video-module"

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const Preval_Intro = (props) => {
    var strapi_url = process.env.GATSBY_STRAPI_SRC, article_link="preval-presentation-confirmation";
    const context = useContainerContext();
    const { property_data, seller_data, logged_user_data, user_tracking } = context.state;
    const { sellerInfoDispatch, UserTrackingDispatch } = context.actions;

    const [ article_data, setArticleData ] = useState('');
    const [ valuation_date, setValutionDate ] = useState('');

    if(props.PresentType === 'preval'){
        article_link = 'preval-presentation-confirmation';
    } else if(props.PresentType === 'custom_client'){
        article_link = 'thank-you-custom-client-presentation';        
    } else{
        article_link = 'custom-presentation-confirmation';
    }


    const { loading, error, data } = GET_ARTICLE(article_link);
    const { loading:seller_loading, error:seller_error, data:sellerData } = SELLER_DETAILS_EMAIL(props.seller_email);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
        sellerInfoDispatch(sellerData && sellerData.sellers[0])
    }, [data, sellerData]);

    useEffect(()=>{
        //seller_data && setValutionDate(moment(seller_data.valuation_date).add(1, 'hour'));
        seller_data && setValutionDate(moment(seller_data.valuation_date));
    }, [seller_data]);

    //console.log("user_tracking thank", user_tracking);

    return <>
        <div className="contact-wrapper intro_section">
            <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                    {
                        props.PresentType === 'custom_client' ? (<>
                            <h2>{article_data && article_data.Modules[0].Title}</h2>
                            <p class="fig-content">{article_data && HTMLReactParser(article_data.Modules[0].Content)}  {props.logged_user.Telephone && `If you would like any clarification on any of my report, please call me on ${props.logged_user.Telephone}`}</p>
                            </>
                        ) : (<>
                            <h2>{article_data && article_data.Modules[0].Title}<br /> <span>{valuation_date && moment(valuation_date).format("dddd Do MMMM")}</span> at <span>{valuation_date && dateFormat(valuation_date, "UTC:h:MMtt")}</span></h2>
                            <p class="fig-content">{article_data && HTMLReactParser(article_data.Modules[0].Content)}</p>
                            </>
                        )
                    }
                    
                    

                    {/* {article_data && article_data.Modules[0].CTA_1_Label && <Link to={article_data.Modules[0].CTA_1_URL ? article_data.Modules[0].CTA_1_URL+'/'+property_id : 'javascript:;'} className="btn btn-primary">{article_data.Modules[0].CTA_1_Label}</Link> }

                    {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : '#'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> } */}
                    {/* <Link to={`${config.presentation_preview}/${props.split_preview_url}`} className="btn btn-primary">Preview Presentation</Link> */}
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <div className="video-wrap">
                        {/* {props.logged_user && props.logged_user.pmd_intro_video ? 
                            <VideoModule video_url={props.logged_user.pmd_intro_video} playing={true} loop={true} /> 
                        : (
                            <span className="coming_soon">Video Coming Soon</span>
                          ) 
                        } */}


                        { props.logged_user && (props.logged_user.photo) ? (
                            <figure>
                                {/* { logged_user && logged_user.logged_user_data && <img src={logged_user.logged_user_data.photo} alt="" /> } */}
                                { props.logged_user && <div className="introImg" style={{backgroundImage:`url("${props.logged_user.photo}")`}}>

                                </div> }
                            </figure>
                            ) : (
                                <span className="coming_soon">Image Coming Soon</span>                                            
                            )
                        }  

                    </div>
                </div>
            </div>
            {/* contact-wrap */}
            </Container>                     
            
        </div>
        {/* contact-wrapper */}
      {/* contact-wrapper */}
    </>
}

export default Preval_Intro
